<template>
  <div class="chatroom-section">
    <div class="container-fluid bg">
      <div class="row">
        <div class="col-xl-12 col-lg-10 col-md-9 col-sm-12 col-12">
          <div class="dashboard-page-header">
            <div class="row">
              <div class="col-md-6 text-start">
                <img src="https://fromyourtutor.com/img/logo.94bac031.png" width="250">
              </div>
              <div class="col-md-6">
                Chatroom
              </div>
            </div>
          </div>
        </div>
        <div class="dashboard-vendor-list" v-if="loading" style="position: absolute;display: flex;text-align:center;justify-content: center;align-items: center;height: 100%;">
          <vue-loaders name="ball-scale-ripple-multiple" color="white" scale="3"></vue-loaders>
        </div>
        <div class="dashboard-vendor-list" v-else-if="isKey">
            <vue-webrtc id="call-canvas" :roomId="roomId" ref="webrtc" v-on:share-started="shareStarted" class="w-full" :enableAudio="enableAudio" :enableVideo="enableVideo" width="100%"  cameraHeight="400"/>
            <div class="flex items-center justify-between">
              <button type="button" @click="copyClipboard" class="controller-button">Share Meeting</button>
              <button type="button" id="join-btn" @click="toggleRoom" class="controller-button">{{hasJoined ? 'Leave Room' : 'Join Room'}}</button>
              <button type="button" id="screen-share-btn" @click="togglescreenShare" v-if="hasJoined" class="controller-button">{{enableScreenShare?'Stop Sharing':'Screen Share'}}</button>
              <!-- <button type="button" id="screen-share-btn" @click="stopScreenShare" v-if="hasJoined" class="controller-button">Stop Sharing</button> -->
              <button type="button" @click="record" v-if="hasJoined && !recording" class="controller-button">Record</button>
              <button type="button" @click="stopRecord" v-if="hasJoined && recording" class="controller-button">Stop Record</button>
              <button type="button" @click="toggleAudio" v-if="hasJoined" class="controller-button"><svg xmlns="http://www.w3.org/2000/svg" v-if="enableAudio" width="16" height="16" fill="currentColor" class="bi bi-mic-fill" viewBox="0 0 16 16"><path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0V3z"/><path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"/></svg><svg xmlns="http://www.w3.org/2000/svg" v-if="!enableAudio" width="16" height="16" fill="currentColor" class="bi bi-mic-mute-fill" viewBox="0 0 16 16"><path d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4.02 4.02 0 0 0 12 8V7a.5.5 0 0 1 1 0v1zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a4.973 4.973 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4zm3-9v4.879L5.158 2.037A3.001 3.001 0 0 1 11 3z"/><path d="M9.486 10.607 5 6.12V8a3 3 0 0 0 4.486 2.607zm-7.84-9.253 12 12 .708-.708-12-12-.708.708z"/></svg></button>
              <button type="button" @click="toggleVideo" v-if="hasJoined" class="controller-button"><svg xmlns="http://www.w3.org/2000/svg" v-if="enableVideo" width="16" height="16" fill="currentColor" class="bi bi-camera-video-fill" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5z"/></svg><svg xmlns="http://www.w3.org/2000/svg" v-if="!enableVideo" width="16" height="16" fill="currentColor" class="bi bi-camera-video-off-fill" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M10.961 12.365a1.99 1.99 0 0 0 .522-1.103l3.11 1.382A1 1 0 0 0 16 11.731V4.269a1 1 0 0 0-1.406-.913l-3.111 1.382A2 2 0 0 0 9.5 3H4.272l6.69 9.365zm-10.114-9A2.001 2.001 0 0 0 0 5v6a2 2 0 0 0 2 2h5.728L.847 3.366zm9.746 11.925-10-14 .814-.58 10 14-.814.58z"/></svg></button>
            </div>
        </div>
        <div class="dashboard-vendor-list" v-else>
          <div style="color:white;" v-if="error_status == 1">
            <h4><strong>Check your meeting code</strong></h4>
            <p>{{ error_msg }}</p>
          </div>
          <div style="color:white;" v-if="error_status == 2">
            <h4><strong>Check your meeting date</strong></h4>
            <p>You have no booking using this id for today</p>
          </div>
          <div style="color:white;" v-if="error_status == 3">
            <h4><strong>Check your meeting time</strong></h4>
            <p>You have no booking using this id for now</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueWebRTC } from 'vue-webrtc';
import axios from 'axios';
import io from 'socket.io-client';
var moment = require('moment-timezone');

export default {
  name:'class-room',
  components: {
    'vue-webrtc': VueWebRTC
  },
  data () {
    return {
      roomId: null,
      hasJoined: false,
      mediaRecorder: {},
      chunks: [],
      userStream: {},
      loading:true,
      isKey:false,
      error_status:1,
      error_msg:'',
      id:null,
      screenSharingActive: false,
      screenSharingStream: null,
      enableVideo:true,
      enableAudio: true,
      enableScreenShare: false,
      recording:false,
      streamId:null,
      socket:null
    }
  },
  beforeMount(){
    this.roomId = this.$route.params.key;
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/get/login',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res=>{
      let login = res.data.data;
      if(login != null){
        axios.get(process.env.VUE_APP_TUTION_SITE_API+'/room/'+this.$route.params.key,{headers:{"Authorization":'Bearer '+login.token}}).then(res=>{
          this.loading = false;
          if(res.data.status){
            let booking = res.data.data
            if(this.checkDate(booking.start_date)){
              if(booking.package == 4){
                if(this.isExpire(booking.created_at)){
                  this.isKey = false;
                  this.error_status = 3
                }else{
                  this.isKey = true;
                }
              }else{
                if(this.checkTime(booking.start_date,booking.start_time,booking.end_time,login.u_type,booking.student,booking.tutor)){
                  this.isKey = true;
                }else{
                  this.isKey = false;
                  this.error_status = 3
                }
              }
            }else{
              this.error_status= 2;
              this.isKey = false;
            }
          }else{
            console.log(res.data);
            this.error_status = 1;
            this.isKey = false;
            this.error_msg = res.data.error
          }
        })
      }else{
        axios.get(process.env.VUE_APP_TUTION_SITE_API+'/auto/room/'+this.$route.params.key,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res=>{
          this.loading = false;
          if(res.data.status){
            let booking = res.data.data
            if(this.checkDate(booking.start_date)){
                this.isKey = true;
            }else{
              this.error_status= 2;
              this.isKey = false;
            }
          }else{
            console.log(res.data);
            this.error_status = 1;
            this.isKey = false;
            this.error_msg = res.data.error
          }
        })
      }
    }) ;
  },
  created() {
    this.socket = io(process.env.VUE_APP_SIGNAL_SERVER_URL);
    this.socket.emit('join-room',{roomId:this.$route.params.key});

    this.socket.on('screen-sharing-stopped', (data) => {
      const streams = this.$refs.webrtc.videoList;      
      streams.forEach((stream, index) => {
        if (data.streamId == stream.id) {
          stream.stream.getTracks().forEach(track => {
            track.stop();
            stream.stream.removeTrack(track);
          });
          this.$refs.webrtc.videoList.splice(index, 1);
          this.enableScreenShare = false;
        }
      });
      console.log(streams);
    });
  },
  mounted () {
    const hash =  window.location.hash;
    if(hash != '') {
      this.roomId = hash.substring(1)
      this.toggleRoom()
    }
  },
  methods: {
    checkDate(c_date){
      const now = new Date(); 
      let date = c_date.split('-');
      return (
        parseInt(date[0]) === now.getFullYear() &&
        parseInt(date[1]) === now.getMonth()+1 &&
        parseInt(date[2]) === now.getDate()
      )
    },
    isExpire(time){
      const currentDate = new Date(time);
      const saudiArabiaTimeZone = 'Asia/Riyadh';
      const formattedDate = new Intl.DateTimeFormat('en-US', {
        timeZone: saudiArabiaTimeZone,
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }).format(currentDate);

      const specificTimestamp =  new Date(formattedDate).getTime();
      const currentTimestamp = Date.now();
      const timeDifference = currentTimestamp - specificTimestamp;
      const isGreaterThan24Hours = timeDifference > 24 * 60 * 60 * 1000;
      return isGreaterThan24Hours;
    },
    checkTime(date,start_time , end_time,type,student,tutor){
      if(type == 'S'){
        const start = new Date(`${date}T${start_time}`);
        const end = new Date(`${date}T${end_time}`);
        const checkTime = new Date();
        return start <= checkTime && checkTime <= end;
      }else if(type == 'T'){
        let student_timezone = this.getTimeZoneByCountry(student.country.sortname);
        let converted_start_time = moment.tz(date+' '+start_time, student_timezone).tz(tutor.timezone);
        let converted_end_time = moment.tz(date+' '+end_time, student_timezone).tz(tutor.timezone);
        const checkTime = new Date();
        return converted_start_time <= checkTime && checkTime <= converted_end_time;
      }else{
        let student_timezone = this.getTimeZoneByCountry(student.country.sortname);
        let current_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let converted_start_time = moment.tz(date+' '+start_time, student_timezone).tz(current_timezone);
        let converted_end_time = moment.tz(date+' '+end_time, student_timezone).tz(current_timezone);
        
        const checkTime = new Date();
        return converted_start_time <= checkTime && checkTime <= converted_end_time;
      }
    },
    getTimeZoneByCountry(countryCode) {
      const timeZones = moment.tz.names();
      for (const timeZone of timeZones) {
        const countryCodes = moment.tz.zone(timeZone).countries();
        if (countryCodes.includes(countryCode)) {
          return timeZone;
        }
      }
      return null;
    },
    onStop () {
      var blob = new Blob(this.chunks, { 'type' : 'video/webm' }); // other types are available such as 'video/webm' for instance, see the doc for more info
      this.chunks = [];
      const file = new File ([blob], 'meeting.webm', { 'type' : 'video/webm' })
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
        a.href = url;
        a.download = 'meeting.webm';
        document.body.appendChild(a);
        a.click()
    },
    pushData (e) {
      this.chunks.push(e.data);
    },
    record () {
      this.mediaRecorder.start();
      this.recording = true;
    },
    stopRecord(){
      this.mediaRecorder.stop();
      this.recording = false;
    },
    toggleAudio(){
      const localStream= this.$refs.webrtc.videoList[0].stream;
      const audioTrack = localStream.getAudioTracks()[0];
      if (audioTrack.enabled) {
        audioTrack.enabled = false;
        this.enableAudio = false;
      }else{
        audioTrack.enabled = true;
        this.enableAudio = true;
      }
    },
    toggleVideo(){
      const localStream= this.$refs.webrtc.videoList[0].stream;
      const videoTrack = localStream.getVideoTracks()[0];
      if (videoTrack.enabled) {
        videoTrack.enabled = false;
        this.enableVideo = false;
      }else{
        videoTrack.enabled = true;
        this.enableVideo = true;
      }
    },
    async toggleRoom () {
      try {
        if(this.hasJoined) {
          this.$refs.webrtc.leave()
          this.hasJoined = false
          this.mediaRecorder.stop()
        } else {
          await this.$refs.webrtc.join();
          this.userStream = this.$refs.webrtc.videoList[0].stream
          this.mediaRecorder = new MediaRecorder(this.userStream)
          this.mediaRecorder.ondataavailable = e => this.pushData(e)
          this.mediaRecorder.onstop = () => this.onStop()
          this.hasJoined = true
        }
      } catch (e) {
        alert(e)
      }
    },
    togglescreenShare() {
      if (!this.enableScreenShare) {
        this.$refs.webrtc.shareScreen();
      } else {
        const streams = this.$refs.webrtc.videoList;
        this.socket.emit('screen-sharing-stopped', {roomId:this.roomId,streamId:this.streamId});
        streams.forEach((stream, index) => {
          if (this.streamId == stream.id) {
            stream.stream.getTracks().forEach(track => {
              track.stop();
              stream.stream.removeTrack(track);
            });
            this.$refs.webrtc.videoList.splice(index, 1);
            this.enableScreenShare = false;
          }
        });
      }
    },
    async addTrack(streamId) {
      try {
        const streams = this.$refs.webrtc.videoList
        streams.forEach(stream => {
          if(stream.id == streamId){
            this.enableScreenShare = true;
            const tracks = stream.stream.getTracks();
            tracks.forEach(track => {
              this.userStream.addTrack(track);
            });
            this.streamId = streamId;
          }
          
        })
      } catch (e) {
          console.log(e);
      }
    },
    shareStarted(streamId) {
      try {
        this.addTrack(streamId)
      } catch (error) {
        console.error('Error in shareStarted event handler:', error);
      }
    },
    async copyClipboard () {
      await navigator.clipboard.writeText(process.env.VUE_APP_URL+'/'+`${this.roomId}`)
      alert('Link copied to clipboard!')
    },
    async share () {
      const shareData = {
                          title: 'Fromyouttutor Meet',
                          text: 'Join my meeting!',
                          url: process.env.VUE_APP_URL+'/'+`${this.roomId}`
                        }
      try {
        await navigator.share(shareData)
      } catch(err) {
        this.copyClipboard()
      }
    }
  }
}
</script>

<style scoped>
.controller-button{
  font-size: 18px;
  background: #000;
  padding: 10px 20px;
  color: #fff;
  border-radius: 15px;
  margin: 10px 10px 10px 10px;
}
.bg {
  background: url(https://fromyourtutor.com/img/couple-bg-img.60638879.jpg)no-repeat;
    background-size: auto;
  background-size: cover;
  height: 100%;
}
.video-list{
  background: none;
}
  .dashboard-page-header {
    margin-bottom: 10px;
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 13px;
    padding-top: 13px;
    background: #fff;
    border-radius: 8px;
    margin-top: 5px;
    padding-left: 15px;
  }
  .dashboard-page-title {
    font-size: 24px !important;
    margin-bottom: 8px;
    line-height: 24px !important;
    font-weight: 400;
  }
</style>