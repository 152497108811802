import { createWebHistory, createRouter } from "vue-router";
import ClassRoom from "@/views/ClassRoom.vue";

const routes = [
  {
    path: "/:key",
    name: "class-room",
    component: ClassRoom,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;