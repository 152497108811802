import {createApp} from 'vue'
import App from './App.vue'
import router from './router' 
import 'setimmediate'; 
import 'vue-loaders/dist/vue-loaders.css';
import VueLoaders from 'vue-loaders';
import VueCookies from 'vue-cookies'

// Polyfill for setImmediate
if (!window.setImmediate) {
    window.setImmediate = function (fn) {
      return setTimeout(fn, 0);
    };
  }
  
  if (!window.clearImmediate) {
    window.clearImmediate = function (id) {
      clearTimeout(id);
    };
  }


createApp(App).use(router).use(VueLoaders).use(VueCookies).mount("#app");
